import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button, Table } from 'react-bootstrap'
import dayjs from 'dayjs'
import cn from 'classnames'

import { app } from 'config'

import AppClipboard from 'components/common/AppClipboard'
import AppTooltip from 'components/common/AppTooltip'
import Spinner from 'components/common/Spinner'
import SubtleBadge from 'components/common/SubtleBadge'

import useBooking from 'hooks/useBooking'
import useUtils from 'hooks/useUtils'

const Bookings = ({ bookings, loading }) => {
  const { formatViewRoute } = useBooking()
  const { monify } = useUtils()

  const bookingList = bookings.map((booking) => {

    const { id, ledger } = booking

    const formattedBookingDate = `${dayjs(booking.booking_date).format('D MMM YYYY')} ${dayjs(booking.created_at).format('hh:mm A')}`
    const formattedSailingDate = dayjs(booking.sailing_date).format('D MMM YYYY')

    return (
      <Fragment key={`booking-${id}`}>
        <tr className="align-middle fs-10">
          <td className="text-nowrap text-primary fw-semibold">
            <Link to={formatViewRoute(id)} className="text-primary">
              {app.bookingPrefix}{id}
            </Link>
          </td>
          <td className="text-nowrap text-primary fw-semibold">
            <Link to={formatViewRoute(id)} className="text-primary">
              {booking.ref_id}
            </Link>
            <AppClipboard text={booking.ref_id} />
          </td>
          <td className="text-nowrap">{booking.ship_name}</td>
          <td className="text-nowrap text-center">{booking.passengers_number}</td>
          <td className="text-nowrap">
            {booking.lead_passenger?.last_name || '-'}
          </td>
          <td className="text-nowrap">
            <AppTooltip title={`Booked: ${formattedBookingDate}`} id="tt-refid" tooltipClass="fs-11">
              <FontAwesomeIcon icon="info-circle" className="me-1 text-info" />
              {formattedSailingDate}
            </AppTooltip>
          </td>
          <td className="text-nowrap">
            <SubtleBadge pill bg={app.bookingType[booking?.type].variant}>
              {app.bookingType[booking?.type].label}
            </SubtleBadge>
            <SubtleBadge pill bg={app.orgs[booking?.booked_with].variant}>
              {app.orgs[booking?.booked_with].label}
            </SubtleBadge>
          </td>
          <td className={cn('text-nowrap fw-semibold', +ledger?.balance > 0 ? 'text-danger' : 'text-success-emphasis')}>
            {monify(ledger?.balance)}
          </td>
          <td className="text-end">
            <Button
              as={Link}
              color="primary"
              size="sm"
              className="m-1"
              to={formatViewRoute(id)}
            >
              View
            </Button>
          </td>
        </tr>
      </Fragment>
    )
  })

  return (
    <div className="timeline-vertical py-0">
      <Table responsive striped hover className="bg-white">
        <thead>
          <tr>
            <th scope="col"><h6 className="text-700">ID</h6></th>
            <th scope="col"><h6 className="text-700">MSC Ref</h6></th>
            <th scope="col"><h6 className="text-700">Ship</h6></th>
            <th scope="col"><h6 className="text-700">Pax</h6></th>
            <th scope="col"><h6 className="text-700">Lead</h6></th>
            <th scope="col"><h6 className="text-700">Sailing</h6></th>
            <th scope="col"><h6 className="text-700">Remarks</h6></th>
            <th scope="col"><h6 className="text-700">Balance</h6></th>
            <th className="text-end" scope="col">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {loading && <td colSpan={`100%`}>
              <Spinner />
            </td>}
          </tr>
          {!loading && (
            bookings.length ? bookingList : <tr>
              <td colSpan="100%" className='fs-10 text-center'>
                No Bookings
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default Bookings
