import { getColor, rgbaColor } from 'helpers/utils'
import { useCallback } from 'react'
import numeral from 'numeral'

const useChart = () => {
  /**
   * Generates the options object for a dual-bar (side-by-side) chart:
   * e.g., Jan: [Year1, Year2], Feb: [Year1, Year2], ...
   */
  const makeTwinBarChartOptions = useCallback(
    (
      xAxisData: string[],
      year1Label: string,
      year1Data: number[],
      year2Label: string,
      year2Data: number[]
    ) => {
      return {
        color: [getColor('success'), getColor('info')],
        tooltip: {
          trigger: 'axis', // 'axis' so the tooltip shows both bars on hover
          padding: [7, 10],
          backgroundColor: getColor('gray-100'),
          borderColor: getColor('gray-300'),
          textStyle: { color: getColor('gray-900') },
          borderWidth: 1,
          transitionDuration: 0,
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          // Show a legend so users can distinguish which bar is Year1 vs Year2
          data: [year1Label, year2Label],
          textStyle: {
            color: getColor('gray-600'),
          },
        },
        xAxis: {
          data: xAxisData,
          splitLine: { show: false },
          splitArea: { show: false },
          axisLabel: {
            color: getColor('gray-600'),
          },
          axisLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'dashed',
            },
          },
          axisTick: { show: false },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'dashed',
            },
          },
          axisLabel: {
            color: getColor('gray-600'),
            formatter: function (value: number) {
              return numeral(value).format('0.[0]a')
            },
          },
        },
        series: [
          {
            name: year1Label,
            type: 'bar',
            data: year1Data,
            barWidth: '30%', // Adjust to your preference
            emphasis: {
              itemStyle: {
                shadowColor: rgbaColor(getColor('gray-1100'), 0.3),
              },
            },
            itemStyle: {
              borderRadius: [3, 3, 0, 0],
            },
          },
          {
            name: year2Label,
            type: 'bar',
            data: year2Data,
            barWidth: '30%', // Adjust to your preference
            emphasis: {
              itemStyle: {
                shadowColor: rgbaColor(getColor('gray-1100'), 0.3),
              },
            },
            itemStyle: {
              borderRadius: [3, 3, 0, 0],
            },
          },
        ],
        grid: {
          top: '13%',
          bottom: 0,
          left: 0,
          right: '20px',
          containLabel: true,
        },
      }
    },
    []
  )

  /**
   * Generates the options object for a bar chart.
   */
  const makeBarChartOptions = useCallback(
    (xAxisData: any[], chartData: any[]) => {
      return {
        color: [getColor('primary'), getColor('info'), getColor('gray-300')],
        tooltip: {
          trigger: 'item',
          padding: [7, 10],
          backgroundColor: getColor('gray-100'),
          borderColor: getColor('gray-300'),
          textStyle: { color: getColor('gray-900') },
          borderWidth: 1,
          transitionDuration: 0,
          axisPointer: {
            type: 'none',
          },
        },
        xAxis: {
          data: xAxisData,
          splitLine: { show: false },
          splitArea: { show: false },

          axisLabel: {
            color: getColor('gray-600'),
          },

          axisLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'dashed',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              color: getColor('gray-300'),
              type: 'dashed',
            },
          },
          axisLabel: {
            color: getColor('gray-600'),
            formatter: function (value: number) {
              return numeral(value).format('0.[0]a')
            },
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: '50%',
            stack: 'one',
            emphasis: {
              itemStyle: {
                shadowColor: rgbaColor(getColor('gray-1100'), 0.3),
              },
            },
            data: chartData,
          },
        ],
        itemStyle: {
          borderRadius: [3, 3, 0, 0],
        },

        barWidth: '13.03px',
        grid: {
          top: '13%',
          bottom: 0,
          left: 0,
          right: '20px',
          containLabel: true,
        },
      }
    },
    []
  )

  /**
   * Generates the options object for a pie chart.
   */
  const makePieChartOptions = useCallback((chartData: any[]) => {
    return {
      legend: {
        show: false,
      },
      series: [
        {
          type: 'pie',
          radius: window.innerWidth < 530 ? '45%' : '60%',
          label: {
            color: getColor('gray-700'),
          },
          center: ['50%', '55%'],
          data: chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: rgbaColor(getColor('gray-600'), 0.5),
            },
          },
        },
      ],
      tooltip: {
        trigger: 'item',
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('gray-1100') },
        borderWidth: 1,
        transitionDuration: 0,
        axisPointer: {
          type: 'none',
        },
      },
    }
  }, [])

  return {
    makeBarChartOptions,
    makeTwinBarChartOptions,
    makePieChartOptions,
  }
}

export default useChart
