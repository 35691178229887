import { callExternalApi } from 'services/external-api.service'
import qs from 'query-string'

const baseEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm`
const bookingsEndpoint = `${baseEndpoint}/bookings`
const contactsEndpoint = `${baseEndpoint}/contacts`

/**
 * Assigns a booking to a contact.
 */
export const assignToContact = async (contactId: number, payload: any) =>
  callExternalApi({
    config: {
      url: `${contactsEndpoint}/${contactId}/bookings`,
      method: 'POST',
      data: payload,
    },
  })

/**
 * Fetches the cost totals of a booking.
 */
export const getCostTotals = async (id: string | number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${id}/cost-totals`,
      method: 'GET',
    },
  })

/**
 * Fetches the ledger of a booking.
 */
export const getLedger = async (id: string | number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${id}/ledger`,
      method: 'GET',
    },
  })

/**
 * Fetches the bokings of a contact.
 */
export const getBookingList = async (contactId: number, query: any = {}) =>
  callExternalApi({
    config: {
      url: `${contactsEndpoint}/${contactId}/bookings?${qs.stringify(query)}`,
      method: 'GET',
    },
  })

/**
 * Fetches a single booking.
 */
export const getOneBooking = async (bookingId: number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${bookingId}`,
      method: 'GET',
    },
  })

/**
 * Deletes a booking.
 */
export const deleteBooking = async (id: number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${id}`,
      method: 'DELETE',
    },
  })

/**
 * Checks if a Booking's file reference is already in use.
 */
export const validateRef = async (refId: any) => {
  return callExternalApi({
    config: {
      url: `${bookingsEndpoint}/ref-id/${refId}/validate`,
      method: 'GET',
    },
  })
}

/**
 * Sends the ticket to the specified email recipient. And optionally to the admin.
 */
export const sendTicket = async (bookingId: string | number, payload: any) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${bookingId}/send-ticket`,
      method: 'POST',
      data: payload,
    },
  })

/**
 * Sends a payment reminder to the booking's contact.
 */
export const sendPaymentReminder = async (bookingId: string | number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${bookingId}/send-payment-reminder`,
      method: 'POST',
    },
  })

/**
 * Transfers a booking to another contact.
 */
export const transferToContact = async (
  bookingId: string | number,
  contactId: string | number
) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${bookingId}/transfer-to-contact/${contactId}`,
      method: 'PATCH',
    },
  })

/**
 * Refreshes a booking's details.
 */
export const refresh = async (id: number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${id}/refresh`,
      method: 'PATCH',
    },
  })

/**
 * Gets the latest bookings.
 */
export const latest = async (limit = 20) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/latest?limit=${limit}`,
      method: 'GET',
    },
  })

/**
 * Toggle booking's bed preference.
 */
export const toggleBedPref = async (id: number) =>
  callExternalApi({
    config: {
      url: `${bookingsEndpoint}/${id}/bed-pref`,
      method: 'PATCH',
    },
  })

const bookingService = {
  assignToContact,
  deleteBooking,
  getBookingList,
  getCostTotals,
  getLedger,
  getOneBooking,
  latest,
  refresh,
  sendTicket,
  sendPaymentReminder,
  transferToContact,
  validateRef,
  toggleBedPref,
}

export default bookingService
