import ReportCruiseRevenuePerMo from 'modules/simple-crm/components/reports/ReportCruiseRevenuePerMo'
import ReportDueBalancesByYear from 'modules/simple-crm/components/reports/ReportDueBalancesByYear'
import ReportRevenuePerSailing from 'modules/simple-crm/components/reports/ReportRevenuePerSailing'
import ReportRevenuePerShip from 'modules/simple-crm/components/reports/ReportRevenuePerShip'
import ReportBookingsPerMonth from 'modules/simple-crm/components/reports/ReportBookingsPerMonth'
import ReportBookingsPerShip from 'modules/simple-crm/components/reports/ReportBookingsPerShip'
import ReportBookingsPerStatus from 'modules/simple-crm/components/reports/ReportBookingsPerStatus'
import ReportBookingsWithPendingTickets from 'modules/simple-crm/components/reports/ReportBookingsWithPendingTickets'
import ReportDueBalancesByMonth from 'modules/simple-crm/components/reports/ReportDueBalancesByMonth'
import ReportBookingsLedgerStatus from 'modules/simple-crm/components/reports/ReportBookingsLedgerStatus'
import ReportBookedWithComparison from 'modules/simple-crm/components/reports/ReportBookedWithComparison'
import ReportBookingTypeComparison from 'modules/simple-crm/components/reports/ReportBookingTypeComparison'
import ReportCompareBookingsByYear from 'modules/simple-crm/components/reports/ReportCompareBookingsByYear'
import ReportCompareContactsByYear from 'modules/simple-crm/components/reports/ReportCompareContactsByYear'
import ReportComparePassengersByYear from 'modules/simple-crm/components/reports/ReportComparePassengersByYear'
import ReportCompareRevenuesByYear from 'modules/simple-crm/components/reports/ReportCompareRevenueByYear'
import ReportBookingsPerDay from 'modules/simple-crm/components/reports/ReportBookingsPerDay'
import ReportTop10Bookings from 'modules/simple-crm/components/reports/ReportTop10Bookings'
import ReportTop10Contacts from 'modules/simple-crm/components/reports/ReportTop10Contacts'
import ReportTop10Passengers from 'modules/simple-crm/components/reports/ReportTop10Passengers'
import ReportTop10Revenues from 'modules/simple-crm/components/reports/ReportTop10Revenues'

import * as echarts from 'echarts/core'
import { BarChart, PieChart } from 'echarts/charts'
import { LegendComponent } from 'echarts/components'

echarts.use([BarChart, PieChart, LegendComponent])

function ReportsPage() {
  return (
    <>
      <div className="row g-3">
        <div className="col-12">
          <ReportBookedWithComparison className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingTypeComparison className="h-100" />
        </div>
        <div className="col-12">
          <ReportCompareBookingsByYear className="h-100" />
        </div>
        <div className="col-12">
          <ReportCompareContactsByYear className="h-100" />
        </div>
        <div className="col-12">
          <ReportComparePassengersByYear className="h-100" />
        </div>
        <div className="col-12">
          <ReportCompareRevenuesByYear className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerDay className="h-100" />
        </div>
        <div className="col-12">
          <ReportTop10Bookings className="h-100" />
        </div>
        <div className="col-12">
          <ReportTop10Contacts className="h-100" />
        </div>
        <div className="col-12">
          <ReportTop10Passengers className="h-100" />
        </div>
        <div className="col-12">
          <ReportTop10Revenues className="h-100" />
        </div>
        <div className="col-6">
          <ReportCruiseRevenuePerMo className="h-100" />
        </div>
        <div className="col-6">
          <ReportRevenuePerShip className="h-100" />
        </div>
        <div className="col-6">
          <ReportDueBalancesByYear className="h-100" />
        </div>
        <div className="col-6">
          <ReportDueBalancesByMonth className="h-100" />
        </div>
        <div className="col-6">
          <ReportBookingsWithPendingTickets className="h-100" />
        </div>
        <div className="col-12">
          <ReportRevenuePerSailing className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerMonth className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerShip className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsPerStatus className="h-100" />
        </div>
        <div className="col-12">
          <ReportBookingsLedgerStatus className="h-100" />
        </div>
      </div>
    </>
  )
}

export default ReportsPage
