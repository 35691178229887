import reportService from 'services/report.service'
import ReportCompareByYear from 'modules/simple-crm/components/reports/ReportCompareByYear'

type Props = {
  className?: string;
};

export default function ReportCompareRevenuesByYear({ className }: Props) {
  return (
    <ReportCompareByYear
      format="monetary"
      className={className}
      title="Revenue: Comparison By Year"
      fetcher={reportService.compareRevenueByYear}
    />
  )
}
