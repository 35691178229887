import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function CRMSelectionPage() {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/simplycruises')
  }, [])

  return null
}
