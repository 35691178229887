import { useMemo } from 'react'
import { Card } from 'react-bootstrap'
import cn from 'classnames'
import dayjs from 'dayjs'

import { app } from 'config'
import { discountLabel } from 'configs/discount'
import AppClipboard from 'components/common/AppClipboard'
import SubtleBadge from 'components/common/SubtleBadge'
import AppTooltip from 'components/common/AppTooltip'
import useUtils from 'hooks/useUtils'

interface BookingQuickInfoProps {
  booking: any;
}

const bookingTypes: any = app.bookingType
const orgs: any = app.orgs
const statuses: any = app.status

function BookingQuickInfo({ booking }: BookingQuickInfoProps) {
  const { monify } = useUtils()

  const { payment_reminder_sent_at, ticket_sent_at } = booking

  /**
   * Calculate the number of days left to sail
   */
  const daysToSailText = useMemo(() => {
    const today = dayjs().format('YYYY-MM-DD')
    const daysToSail = Math.ceil(
      dayjs(booking.sailing_date).diff(today, 'days', true)
    )

    const s = daysToSail === 1 ? '' : 's'

    return daysToSail > -1
      ? `${daysToSail} day${s} left`
      : `${Math.abs(daysToSail)} day${s} ago`
  }, [booking.sailing_date])

  return (
    <Card>
      <Card.Body>
        <div>
          <div className="d-flex justify-content-between gap-2 mb-1">
            <small className="fw-bold text-500">
              {app.bookingPrefix}
              {booking.id}
            </small>
            <div className="d-flex gap-1">
              <SubtleBadge pill bg={orgs[booking?.booked_with].variant}>
                {orgs[booking?.booked_with].label}
              </SubtleBadge>
              <SubtleBadge pill bg={bookingTypes[booking?.type].variant}>
                {bookingTypes[booking?.type].label}
              </SubtleBadge>
              <SubtleBadge
                pill
                bg={statuses[booking.status]?.variant || 'secondary'}
              >
                {booking.status}
              </SubtleBadge>
            </div>
          </div>
          <p className="fw-bold text-800">
            Booking - {booking.ref_id}
            <AppClipboard text={booking.ref_id} />
          </p>
          <hr className="border border-dashed" />
          <div className="mt-2">
            <label className="mb-0">Booking value</label>
            <p className="fw-bold text-800 mb-0 fs-5">
              {monify(booking?.totals?.booking_value)}
            </p>
          </div>
          <hr className="border border-dashed" />
          <div className="mt-2">
            <table className="fs-10 w-100 table table-striped">
              <tbody>
                <tr>
                  <td className="px-2">Booked</td>
                  <td className="fw-semibold text-end px-2">
                    <AppTooltip
                      title={dayjs(booking.created_at).format(
                        'DD MMM YYYY hh:mm A'
                      )}
                      id="tt-booked_at"
                    >
                      {dayjs(booking.booking_date).format('DD MMM YYYY')}{' '}
                    </AppTooltip>
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Sailing</td>
                  <td className="fw-semibold text-end px-2">
                    {dayjs(booking.sailing_date).format('DD MMM YYYY')}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">When?</td>
                  <td className="fw-semibold text-end px-2">
                    {daysToSailText}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Balance</td>
                  <td
                    className={cn(
                      'fw-semibold text-end px-2',
                      +booking?.totals?.balance !== 0
                        ? 'text-danger'
                        : 'text-success'
                    )}
                  >
                    {monify(booking?.totals?.balance)}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Final Payment Date</td>
                  <td className="fw-semibold text-end px-2">
                    {dayjs(booking.final_payment_date).format('DD MMM YYYY')}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">FPRS Sent</td>
                  <td className="fw-semibold text-end px-2">
                    {payment_reminder_sent_at ? (
                      dayjs(payment_reminder_sent_at).format('DD MMM YYYY')
                    ) : (
                      <span className="fw-bold">-</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">e-tickets Sent</td>
                  <td className="fw-semibold text-end px-2">
                    {ticket_sent_at ? (
                      dayjs(ticket_sent_at).format('DD MMM YYYY')
                    ) : (
                      <span className="fw-bold">-</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Cruise ID</td>
                  <td className="fw-semibold text-end px-2">
                    <div className="d-inline-flex gap-x-1 fs-11 aligb-items-center">
                      {booking.cruise_id}
                      <AppClipboard text={booking.cruise_id} className="mt-1" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Discounts</td>
                  <td className="fw-semibold text-end px-2">
                    {(booking.session?.discounts ?? []).map((discount: any) => (
                      <SubtleBadge pill bg="info" key={discount}>
                        {discountLabel[discount] ?? discount}
                      </SubtleBadge>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default BookingQuickInfo
