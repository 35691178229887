import { useMemo } from 'react'
import { Card, Nav, Tab } from 'react-bootstrap'
import dayjs from 'dayjs'
import SimpleBar from 'simplebar-react'

import { app } from 'config'
import SubtleBadge from 'components/common/SubtleBadge'
import useUtils from 'hooks/useUtils'

interface BookingCruisesProps {
  className?: string;
  bedPref?: string;
  cruises: any[];
  onToggleBed: () => void;
}

const statuses: any = app.status

function BookingCruises({
  className,
  cruises,
  bedPref,
  onToggleBed,
}: BookingCruisesProps) {
  const { formatHundredthHours } = useUtils()

  // Sort cruises by status
  const sortedCruises: any[] = useMemo(() => {
    const cancelledCruises: any[] = []

    const nonCancelledCruises = cruises.filter((cruise) => {
      // If cruise is cancelled, add it to the `cancelledCruises` array
      if (cruise.status === app.status.CXL.key) {
        cancelledCruises.push(cruise)
        return false
      }

      return true
    })

    // Place cancelled cruises at the end of the list
    return [...nonCancelledCruises, ...cancelledCruises]
  }, [cruises])

  return (
    <Card className={className}>
      <Card.Body className="px-0 pb-0">
        <p className="fw-bold text-900" style={{ padding: '0 20px' }}>
          Cruise Sailings
        </p>
        <Tab.Container defaultActiveKey="cruise-0">
          <SimpleBar>
            <Card.Header className="p-0 border-bottom">
              <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details tabs-highlighted">
                {sortedCruises.map((cruise, index: number) => (
                  <Nav.Item key={cruise.id}>
                    <Nav.Link
                      eventKey={`cruise-${index}`}
                      className="d-flex align-items-center py-3 px-x1 mb-0 bordered-active-tab"
                    >
                      <h6 className="text-600 mb-0 ms-1">
                        Cruise Overview{' '}
                        {sortedCruises.length > 1 ? index + 1 : ''}
                      </h6>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
          </SimpleBar>
          <Card.Body>
            <Tab.Content>
              {sortedCruises.map((cruise, index) => (
                <Tab.Pane eventKey={`cruise-${index}`} key={cruise.id}>
                  <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3">
                    <div>
                      <small className="d-block">Status:</small>
                      <SubtleBadge
                        pill
                        bg={statuses[cruise.status]?.variant || 'secondary'}
                      >
                        {cruise.status}
                      </SubtleBadge>
                    </div>
                    <div>
                      <small>Embarkation Time:</small>
                      <p className="fw-bold text-800">
                        {cruise.check_in_time
                          ? formatHundredthHours(cruise.check_in_time)
                          : '-'}
                      </p>
                    </div>
                    <div>
                      <small>Experience:</small>
                      <p className="fw-bold text-800">
                        {(app.experience as any)[cruise.experience] ??
                          cruise.experience}
                      </p>
                    </div>
                    <div>
                      <small>Cabin:</small>
                      <p className="fw-bold text-800">{cruise.number}</p>
                    </div>
                    <div>
                      <small>Deck:</small>
                      <p className="fw-bold text-800">
                        {cruise.deck_code} - {cruise.deck_desc}
                      </p>
                    </div>
                    <div>
                      <small>Cabin Grade:</small>
                      <p className="fw-bold text-800">{cruise.cabin_grade}</p>
                    </div>
                    <div>
                      <small>Passengers:</small>
                      <p className="fw-bold text-800">
                        {cruise.passenger_count}
                      </p>
                    </div>
                    <div>
                      <small>Dining:</small>
                      <p className="fw-bold text-800">
                        {cruise.dining_pref || '-'}
                      </p>
                    </div>
                    <div>
                      <small>Bed Pref:</small>
                      <p className="fw-bold text-800">
                        {bedPref || app.bedPref.KING}
                        <small
                          className="text-primary fw-medium text-decoration-underline cursor-pointer ms-1 d-inline-block"
                          onClick={onToggleBed}
                        >
                          Change to{' '}
                          {bedPref === app.bedPref.KING
                            ? app.bedPref.TWIN
                            : app.bedPref.KING}
                        </small>
                      </p>
                    </div>
                    <div>
                      <small>Gratuities:</small>
                      <p className="fw-bold text-800">Included</p>
                    </div>
                    <div>
                      <small>Promo Fare:</small>
                      <p className="fw-bold text-800">{cruise.promo_fare}</p>
                    </div>
                    <div>
                      <small>Departure-Arrival:</small>
                      <p className="fw-bold text-800">
                        {dayjs(cruise.sailing_date).format('DD MMM YYYY')}{' '}
                        {formatHundredthHours(cruise.sailing_time)}
                        <br />
                        {dayjs(cruise.return_date).format('DD MMM YYYY')}{' '}
                        {formatHundredthHours(cruise.return_time)}
                      </p>
                    </div>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card.Body>
    </Card>
  )
}

export default BookingCruises
