import { Button, Form, Modal, Spinner, Table } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import { useEffect, useMemo, useState } from 'react'
import toolService from 'services/tool.service'
import { useAsyncDebounce } from 'react-table'
import { app } from 'config'
import useUtils from 'hooks/useUtils'
import { toast } from 'react-toastify'
import bunService from 'services/bun.service'

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  instance?: any;
};

const reasonOptions = [
  'Cabin change',
  'Excursion change',
  'Fare update',
  'Flight update',
  'Itinerary change',
  'Operations update',
  'Port Update',
  'Service update',
  'Special assistance',
]

const defaultForm = {
  title: '',
  reason: reasonOptions[0],
  details: '',
}

export default function ToolBUNModal({
  show,
  onClose,
  onSuccess,
  instance,
}: Props) {
  const [form, setForm] = useState({ ...defaultForm })
  const [formValidated, setFormValidated] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [selections, setSelections] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)

  const { formatDate } = useUtils()

  const availableOptions = useMemo(
    () =>
      options.filter((option) => {
        return !selections.some((selection) => selection.id === option.id)
      }),
    [options, selections]
  )

  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setFormValidated(false)

    if (show) {
      // Set the form values to the instance values if it exists
      setForm(instance || { ...defaultForm })

      // Reset the state
      setShowSearch(true)
      setOptions([])
      setSelections([])
    }
  }, [show])

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    // Reset the form values
    setForm({ ...defaultForm })

    // Reset the ref validation states
    setFormValidated(false)

    // Inform the parent component to close the modal
    onClose()
  }

  /**
   * Handle form submission.
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const formEl = event.currentTarget

    if (selections.length === 0) {
      toast('Please select at least one booking.')
      return
    }

    // Validate the form
    if (formEl.checkValidity() !== false) {
      // If valid, send the BUNs
      send(form)

      setTimeout(() => {
        // Reset the form values
        setForm({ ...defaultForm })
      }, 500)
    } else {
      setFormValidated(true)
    }
  }

  /**
   * Debounce search input
   */
  const handleSearchInput = useAsyncDebounce<any>((e: any) => {
    if (e.target.value) {
      searchBookings(e.target.value)
    } else {
      setOptions([])
    }
  }, 800)

  /**
   * Searches bookings by reference ID
   */
  const searchBookings = async (refId: string) => {
    setOptions([])
    setLoading(true)

    const { data }: any = await toolService.searchBookings(refId, 3)

    setOptions(data)
    setLoading(false)
  }

  /**
   * Selects a booking from the search results.
   */
  const selectBooking = (booking: any) => {
    const isSelected = selections.some(
      (selected) => selected.id === booking.id
    )

    if (!isSelected) {
      setSelections([...selections, booking])
    }
  }

  /**
   * Removes a booking from the selected bookings.
   */
  const removeSelection = (bookingId: number) => {
    const isSelected = selections.some((selected) => selected.id === bookingId)

    if (isSelected) {
      setSelections(selections.filter((booking) => booking.id !== bookingId))
    }
  }

  /**
   * Sends the booking update notifications.
   */
  const send = async (form: any) => {
    setLoading(true)

    const { error } = await bunService.send({
      ...form,
      booking_ids: selections.map((booking) => booking.id),
    })

    if (error) {
      const message = 'Failed to send booking notification.'
      toast(error.message || message)
      setLoading(false)
      onClose()
      return
    }

    toast('Booking notifications, queued!')
    setLoading(false)

    // Inform the parent component that the submission was successful
    onSuccess()
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false}>
      <Modal.Body>
        <strong className="d-block py-3">
          Create Booking Update Notification
        </strong>
        {showSearch && (
          <div className="border p-3 rounded-2 mb-3">
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Search</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter a booking reference"
                  onInput={handleSearchInput}
                />
              </Form.Group>
            </div>
            <Form.Label className="my-0">Results</Form.Label>
            <p className="fs-10 text-info">Click a row to select.</p>
            <Table responsive striped hover>
              <thead>
                <tr>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">ID</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">MSC Ref</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Sailing Date</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={3} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !availableOptions.length && (
                    <td colSpan={3} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {availableOptions.map((booking) => (
                  <tr
                    key={booking.id}
                    className="cursor-pointer"
                    onClick={() => selectBooking(booking)}
                  >
                    <td className="text-primary fw-semibold">
                      {app.bookingPrefix}
                      {booking.id}
                    </td>
                    <td className="text-primary fw-semibold">
                      {booking.ref_id}
                    </td>
                    <td className="text-primary fw-semibold">
                      {formatDate(booking.date)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <div className="d-flex">
          <p
            className="fs-11 text-decoration-underline text-muted cursor-pointer m-0"
            onClick={() => setShowSearch(!showSearch)}
          >
            {showSearch ? 'Hide search box' : 'Show search box'}
          </p>
        </div>
        <hr className="mb-3" />
        <div>
          <Form.Label className="my-0">
            Selected Bookings ({selections.length})
          </Form.Label>
          {selections.length > 0 && (
            <p className="fs-11 text-danger">Click a row to remove.</p>
          )}
          <Table responsive striped hover>
            <thead>
              <tr>
                <th scope="col">
                  <h6 className="text-700 fw-semibold">ID</h6>
                </th>
                <th scope="col">
                  <h6 className="text-700 fw-semibold">MSC Ref</h6>
                </th>
                <th scope="col">
                  <h6 className="text-700 fw-semibold">Sailing Date</h6>
                </th>
              </tr>
            </thead>
            <tbody className="fs-10">
              <tr>
                {!selections.length && (
                  <td colSpan={3} className="text-center">
                    No selected bookings yet.
                  </td>
                )}
              </tr>
              {selections.map((booking) => (
                <tr
                  key={booking.id}
                  className="cursor-pointer"
                  onClick={() => removeSelection(booking.id)}
                >
                  <td className="text-primary fw-semibold">
                    {app.bookingPrefix}
                    {booking.id}
                  </td>
                  <td className="text-primary fw-semibold">{booking.ref_id}</td>
                  <td className="text-primary fw-semibold">
                    {formatDate(booking.date)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div>
          <Form
            noValidate
            validated={formValidated}
            className="mt-4"
            onSubmit={handleSubmit}
          >
            <Flex direction="column">
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder={'Title'}
                  value={form.title}
                  onChange={({ target }) =>
                    setForm({
                      ...form,
                      title: target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 w-100">
                <Form.Label>Reason</Form.Label>
                <Form.Select
                  value={form.reason}
                  onChange={(e) => setForm({ ...form, reason: e.target.value })}
                  required
                >
                  {reasonOptions.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Details</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  required
                  placeholder={'Details'}
                  value={form.details}
                  onChange={({ target }) =>
                    setForm({
                      ...form,
                      details: target.value,
                    })
                  }
                />
              </Form.Group>
            </Flex>
            <Flex alignItems="center" className="justify-content-end gap-2">
              <Button variant="light" onClick={closeModal} disabled={loading}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                {loading ? 'Sending...' : 'Send'}
              </Button>
            </Flex>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
