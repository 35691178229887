import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'
import dayjs from 'dayjs'

import useUtils from 'hooks/useUtils'
import useChart from 'hooks/useChart'
import useReport from 'hooks/useReport'

import reportService from 'services/report.service'

type Props = {
  className?: string;
};

export default function ReportBookingsPerDay({ className }: Props) {
  const { makeBarChartOptions } = useChart()
  const { currentYear, monthOptions, yearOptions } = useReport()
  const { monify, monthName } = useUtils()

  const [chartXAxis, setChartXAxis] = useState<string[]>([])
  const [countChartData, setCountChartData] = useState<any[]>([])
  const [revenueChartData, setRevenueChartData] = useState<any[]>([])

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  // We add 1 to month because dayjs month starts at 0
  const currentMonth = useMemo(() => dayjs().month() + 1, [])
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)

  const [selectedYear, setSelectedYear] = useState(currentYear)

  // Get results on initial load, also when selected month or year changes
  useEffect(() => {
    getResults()
  }, [selectedMonth, selectedYear])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await reportService.getBookingsPerDay({
      month: selectedMonth,
      year: selectedYear,
    })

    setResults(data)

    // Format x-axis labels
    setChartXAxis(data.map((result: any) => result.day))

    // Format data for Booking Count chart
    setCountChartData(data.map((result: any) => result.count))

    // Format data for Booking Revenue chart
    setRevenueChartData(data.map((result: any) => +result.revenue))

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">Bookings Per Day</p>
          <div className="d-flex flex-between-center gap-3">
            <Form.Group className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon="filter" className="me-2" />
              <Form.Select
                value={selectedMonth}
                onChange={(e: any) => setSelectedMonth(e.target.value)}
                disabled={loading}
              >
                {monthOptions.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center gap-2">
              <Form.Select
                value={selectedYear}
                onChange={(e: any) => setSelectedYear(e.target.value)}
                disabled={loading}
              >
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-lg-5">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Day</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Count</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Revenue</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={4} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={4} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>
                      {monthName(selectedMonth)} {result.day}
                    </td>
                    <td>{result.count}</td>
                    <td>{monify(result.revenue)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-7">
            <div>
              <p className="fw-bold m-0">Booking Count Per Day</p>
              <ReactEChartsCore
                echarts={echarts}
                option={makeBarChartOptions(chartXAxis, countChartData)}
                style={{ height: '18.75rem' }}
              />
            </div>
            <div className="mt-5">
              <p className="fw-bold m-0">Booking Revenue Per Day</p>
              <ReactEChartsCore
                echarts={echarts}
                option={makeBarChartOptions(chartXAxis, revenueChartData)}
                style={{ height: '18.75rem' }}
              />
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
