import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Card, Col, Dropdown, Row, Tab, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import SimpleBar from 'simplebar-react'

import { app } from 'config'
import { resetBunsCount, resetNotesCount } from 'store/slices/appSlice'

import BookingContact from 'modules/simple-crm/components/bookings/BookingContact.tsx'
import BookingCostings from 'modules/simple-crm/components/bookings/BookingCostings'
import BookingCruises from 'modules/simple-crm/components/bookings/BookingCruises'
import BookingFlightSegments from 'modules/simple-crm/components/bookings/BookingFlightSegments'
import BookingItinerary from 'modules/simple-crm/components/bookings/BookingItinerary'
import BookingOnboardServices from 'modules/simple-crm/components/bookings/BookingOnboardServices'
import BookingOverview from 'modules/simple-crm/components/bookings/BookingOverview'
import BookingPassengers from 'modules/simple-crm/components/bookings/BookingPassengers'
import BookingQuickInfo from 'modules/simple-crm/components/bookings/BookingQuickInfo'
import BookingRemarks from 'modules/simple-crm/components/bookings/BookingRemarks'
import BookingTransfers from 'modules/simple-crm/components/bookings/BookingTransfers'
import BookingSendTicketModal from 'modules/simple-crm/components/bookings/BookingSendTicketModal'
import LedgerSheet from 'modules/simple-crm/components/ledgers/LedgerSheet'

import Notes from 'modules/simple-crm/components/notes/Notes'

import AppTooltip from 'components/common/AppTooltip'
import ConfirmationModal from 'components/common/ConfirmationModal'
import IconButton from 'components/common/IconButton'
import SubtleBadge from 'components/common/SubtleBadge'
import Spinner from 'components/common/Spinner'

import bookingService from 'services/booking.service'
import paxService from 'services/passenger.service'
import BookingTravelWith from 'modules/simple-crm/components/bookings/BookingTravelWith'
import BookingActivities from 'modules/simple-crm/components/bookings/BookingActivities'
import BookingBuns from 'modules/simple-crm/components/bookings/BookingBuns'

const BookingDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()

  // Reference to the notes tab element
  const noteTab = useRef(null)

  const [booking, setBooking] = useState(null)
  const [loading, setLoading] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showRefreshConfirmation, setShowRefreshConfirmation] = useState(false)
  const [showTicketModal, setShowTicketModal] = useState(false)
  const [confirmBanUpserFor, setConfirmBanUpserFor] = useState(null)
  const [banAction, setBanAction] = useState(null)
  const [showConfirmToggleBed, setShowConfirmToggleBed] = useState(false)

  // FPRS states
  const [sendingPaymentReminder, setSendingPaymentReminder] = useState(false)
  const [showFprsConfirmation, setShowFprsConfirmation] = useState(false)

  const notesCount = useSelector(
    (state) => state.app.notesCount[app.morphKeys.BOOKING]
  )

  const bunsCount = useSelector((state) => state.app.bunsCount)

  useEffect(() => {
    // Reset the notes and BUNs count when the component mounts
    dispatch(resetNotesCount(app.morphKeys.BOOKING))
    dispatch(resetBunsCount())

    getBooking()
  }, [])

  /**
   * Formats the "refreshed at" date
   */
  const refreshedAt = useMemo(() => {
    const { refreshed_at = null } = booking || {}
    return refreshed_at
      ? dayjs(refreshed_at).format('DD-MM-YYYY HH:MM')
      : 'Never'
  }, [booking?.refreshed_at])

  /**
   * Deletes the booking
   */
  const deleteBooking = async () => {
    setShowDeleteConfirmation(false)
    setLoading(true)

    await bookingService.deleteBooking(params.id)

    setLoading(false)
    goBack()
  }

  /**
   * Fetches the booking details
   */
  const getBooking = useCallback(async () => {
    setLoading(true)

    const { data } = await bookingService.getOneBooking(params.id)

    setBooking(data)
    setLoading(false)
  }, [])

  /**
   * Navigates back to the previous page
   */
  const goBack = () =>
    navigate(`/simplycruises/contacts/${booking.contact_id}`)

  /**
   * Clicks the notes tab and opens the notes modal
   */
  const handleNoteAdd = () => {
    noteTab.current.click()
    setShowNotesModal(true)
  }

  /**
   * Refreshes the booking details
   */
  const handleRefresh = async () => {
    // Hide the confirmation modal
    setShowRefreshConfirmation(false)

    // Show the "refreshing" message
    setRefreshing(true)

    // Show the spinner
    setLoading(true)

    // Refresh the booking data on the server
    const { error } = await bookingService.refresh(params.id)

    setLoading(false)
    setRefreshing(false)

    error
      ? toast(error.message || 'Unexpected Error')
      : toast('Booking data refreshed!')
  }

  /**
   * Sends a payment reminder to the booking's contact
   */
  const sendPaymentReminder = async () => {
    // Disable confirmation modal buttons
    setSendingPaymentReminder(true)

    await bookingService.sendPaymentReminder(params.id)

    // Reset the confirmation modal loading state
    setSendingPaymentReminder(false)

    // Hide the confirmation modal
    setShowFprsConfirmation(false)

    toast('Payment reminder sent!')

    // Set the `payment_reminder_sent_at` field to the current date
    setBooking({
      ...booking,
      payment_reminder_sent_at: dayjs().format('YYYY-MM-DD'),
    })
  }

  /**
   * Updates the booking cost totals
   */
  const updateCostTotals = async () => {
    // Fetch the updated cost totals from the server
    const { data: totals } = await bookingService.getCostTotals(params.id)

    // Update the booking state with the new totals
    setBooking({ ...booking, totals })
  }

  /**
   * Updates the `ticket_sent_at` field to the current date
   */
  const updateTicketSentDate = () => {
    setBooking({
      ...booking,
      ticket_sent_at: dayjs().format('YYYY-MM-DD'),
    })
  }

  /**
   * Asks for confirmation before updating the ban status of a passenger
   */
  const askBanConfirmation = (action, pax) => {
    setConfirmBanUpserFor(pax)
    setBanAction(action)
  }

  /**
   * Confirm the ban/unban action for a passenger
   */
  const confirmBanStatusUpdate = async () => {
    const pax = confirmBanUpserFor
    let { passengers } = booking
    let ban_id = null

    if (banAction === 'ban') {
      const { data } = await paxService.banPax(pax)
      ban_id = data.id
    } else {
      await paxService.unban(pax?.ban_id)
    }

    // Either set or remove the ban_id of the passenger
    const index = passengers.findIndex((p) => p.id === pax.id)
    passengers[index].ban_id = ban_id

    // Update the booking state with the new passengers array
    // to reflect the ban status change
    setBooking({ ...booking, passengers })

    toast('Ban status updated!')
    setConfirmBanUpserFor(null)
  }

  /**
   * Confirm the bed preference toggle
   */
  const confirmBedPrefToggle = async () => {
    // Toggle the bed preference on the server
    const { data, error } = await bookingService.toggleBedPref(booking.id)

    // Hide the confirmation modal
    setShowConfirmToggleBed(false)

    if (error) {
      toast(error.message || 'Failed to toggle bed preference')
      return
    }

    // Update the booking state with the new `bed_pref` value
    setBooking({ ...booking, bed_pref: data.bed_pref })

    toast('Bed preference toggled!')
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex flex-between-center">
          <IconButton
            onClick={goBack}
            variant="success"
            size="sm"
            icon="arrow-left"
          />

          <div className="d-flex gap-1 items-center">
            <IconButton
              variant="success"
              size="sm"
              icon="file"
              iconAlign="middle"
              className="d-none d-sm-block me-2"
              onClick={handleNoteAdd}
            >
              <span className="d-none d-xl-inline-block ms-1">Add Note</span>
            </IconButton>
            <IconButton
              variant="primary"
              size="sm"
              icon="ticket-alt"
              iconAlign="middle"
              className="d-none d-sm-block me-2"
              onClick={() => setShowTicketModal(true)}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Send e-Tickets
              </span>
            </IconButton>
            <IconButton
              variant="info"
              size="sm"
              icon="bolt"
              iconAlign="middle"
              className="d-none d-sm-block me-2"
              onClick={() => setShowFprsConfirmation(true)}
            >
              <span className="d-none d-xl-inline-block ms-1">
                Trigger FPRS
              </span>
            </IconButton>
            <AppTooltip title={`Refreshed: ${refreshedAt}`} id="tt-refresh">
              <IconButton
                variant="warning"
                size="sm"
                icon="redo"
                iconAlign="middle"
                className="d-none d-sm-block me-2"
                onClick={() => setShowRefreshConfirmation(true)}
              >
                <span className="d-none d-xl-inline-block ms-1">Refresh</span>
              </IconButton>
            </AppTooltip>
            <IconButton
              variant="danger"
              size="sm"
              icon="trash"
              iconAlign="middle"
              className="d-none d-sm-block me-2"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <span className="d-none d-xl-inline-block ms-1">Delete</span>
            </IconButton>
            <Dropdown
              align="end"
              className="btn-reveal-trigger d-inline-block d-sm-none"
            >
              <Dropdown.Toggle split variant="falcon-default" size="sm">
                <FontAwesomeIcon icon="ellipsis-v" className="fs-11" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item className="d-sm-none" onClick={handleNoteAdd}>
                    Add Note
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-sm-none"
                    onClick={() => setShowTicketModal(true)}
                  >
                    Send e-Tickets
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-sm-none"
                    onClick={() => setShowFprsConfirmation(true)}
                  >
                    Trigger FPRS
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-sm-none"
                    onClick={() => setShowRefreshConfirmation(true)}
                  >
                    Refresh
                    <small className="d-block">Refreshed: {refreshedAt}</small>
                  </Dropdown.Item>
                  <Dropdown.Divider className="d-sm-none" />
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => setShowDeleteConfirmation(true)}
                  >
                    Delete
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card.Header>
      </Card>
      {booking && <BookingContact booking={booking} />}
      <Row className="g-3">
        {loading ? (
          <>
            <Spinner />
            {refreshing && (
              <p className="text-center">Refreshing booking details...</p>
            )}
          </>
        ) : (
          <>
            <Col xxl={3} xl={4} className="order-xl-0">
              <BookingQuickInfo booking={booking} />
              <BookingItinerary booking={booking} />
              <BookingCostings booking={booking} className="mt-3" />
            </Col>
            <Col xxl={9} xl={8}>
              <Card>
                <Tab.Container defaultActiveKey="details">
                  <SimpleBar>
                    <Card.Header className="p-0 border-bottom">
                      <Nav className="nav-tabs border-0 flex-nowrap tab-contact-details">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="details"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="info-circle"
                              className="text-600 tab-icon"
                            />
                            <h6 className="text-600 mb-0 ms-1">Details</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            ref={noteTab}
                            eventKey="notes"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="file-alt"
                              className="text-600"
                            />
                            <h6 className="text-600 mb-0 ms-1">
                              Notes{' '}
                              {notesCount ? (
                                <SubtleBadge
                                  pill
                                  className="ms-1 bg-success text-white"
                                >
                                  {notesCount}
                                </SubtleBadge>
                              ) : (
                                ''
                              )}
                            </h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="ledger"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="table"
                              className="text-600"
                            />
                            <h6 className="text-600 mb-0 ms-1">Ledger</h6>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="bun"
                            className="d-flex align-items-center py-3 px-x1 mb-0"
                          >
                            <FontAwesomeIcon
                              icon="paper-plane"
                              className="text-600"
                            />
                            <h6 className="text-600 mb-0 ms-1">
                              BUN{' '}
                              {bunsCount ? (
                                <SubtleBadge
                                  pill
                                  className="ms-1 bg-success text-white"
                                >
                                  {bunsCount}
                                </SubtleBadge>
                              ) : (
                                ''
                              )}
                            </h6>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Card.Header>
                  </SimpleBar>
                  <Card.Body className="bg-body-tertiary">
                    <Tab.Content>
                      <Tab.Pane eventKey="details">
                        <BookingOverview booking={booking} />

                        <BookingPassengers
                          className="mt-3"
                          passengers={booking.passengers}
                          handleBanStatusUpdate={askBanConfirmation}
                        />

                        <BookingCruises
                          className="mt-3"
                          cruises={booking.cruises}
                          bedPref={booking.bed_pref}
                          onToggleBed={() => setShowConfirmToggleBed(true)}
                        />

                        {booking.flight_segments.length > 0 && (
                          <BookingFlightSegments
                            className="mt-3"
                            segments={booking.flight_segments}
                          />
                        )}
                        <BookingTransfers className="mt-3" booking={booking} />
                        <BookingOnboardServices
                          className="mt-3"
                          services={booking.onboard_services}
                        />
                        <BookingActivities
                          className="mt-3"
                          activities={booking.activities}
                        />
                        <BookingTravelWith
                          className="mt-3"
                          bookings={booking.linked_bookings}
                        />
                        <BookingRemarks
                          className="mt-3"
                          remarks={booking.remarks}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="notes">
                        <Notes
                          onFormClose={() => setShowNotesModal(false)}
                          showNotesModal={showNotesModal}
                          noteableId={params.id}
                          noteableType={app.morphKeys.BOOKING}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="ledger">
                        <LedgerSheet
                          bookingId={params.id}
                          onUpdate={updateCostTotals}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="bun">
                        <BookingBuns bookingId={params.id} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>
            </Col>
          </>
        )}
      </Row>
      <ConfirmationModal
        show={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleConfirm={() => deleteBooking()}
        confirmationText="Do you really want to delete this booking?"
        confirmButtonText="Delete"
      />
      <ConfirmationModal
        show={showRefreshConfirmation}
        handleClose={() => setShowRefreshConfirmation(false)}
        handleConfirm={() => handleRefresh()}
        confirmationText="This will refresh this booking's details. Are you sure?"
        confirmButtonText="Proceed"
      />
      <ConfirmationModal
        show={showFprsConfirmation}
        handleClose={() => setShowFprsConfirmation(false)}
        handleConfirm={() => sendPaymentReminder()}
        loading={sendingPaymentReminder}
        confirmationText="This will send a payment reminder to this booking's contact. Are you sure?"
        confirmButtonText="Send"
      />
      <BookingSendTicketModal
        closeModal={() => setShowTicketModal(false)}
        booking={booking}
        show={showTicketModal}
        onSentToContact={updateTicketSentDate}
      />
      <ConfirmationModal
        show={!!confirmBanUpserFor}
        handleClose={() => setConfirmBanUpserFor(null)}
        handleConfirm={() => confirmBanStatusUpdate()}
        confirmationText={`Are you sure you want to ${banAction} this passenger?`}
        confirmButtonText="Confirm"
      />
      <ConfirmationModal
        show={!!confirmBanUpserFor}
        handleClose={() => setConfirmBanUpserFor(null)}
        handleConfirm={() => confirmBanStatusUpdate()}
        confirmationText={`Are you sure you want to ${banAction} this passenger?`}
        confirmButtonText="Confirm"
      />
      <ConfirmationModal
        show={!!showConfirmToggleBed}
        handleClose={() => setShowConfirmToggleBed(false)}
        handleConfirm={() => confirmBedPrefToggle()}
        confirmationText={`Are you sure you want to change bed pref to ${
          booking?.bed_pref === app.bedPref.KING
            ? `"${app.bedPref.TWIN}"`
            : `"${app.bedPref.KING}"`
        }?`}
        confirmButtonText="Confirm"
      />
    </>
  )
}

export default BookingDetails
