import ReportTop10Records from './ReportTop10Records'
import reportService from 'services/report.service'

type Props = {
  className?: string;
};

export default function ReportTop10Passengers({ className }: Props) {
  return (
    <ReportTop10Records
      className={className}
      title="Top 10 All-Time Passengers"
      fetcher={reportService.getTop10Passengers}
      valueType="number"
    />
  )
}
