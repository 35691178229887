import { createSlice, Slice } from '@reduxjs/toolkit'

import { app } from 'config'

export const appSlice: Slice = createSlice({
  name: 'app',

  initialState: {
    notesCount: {
      [app.morphKeys.BOOKING]: 0,
      [app.morphKeys.CONTACT]: 0,
    },

    bookingsCount: 0,
    bunCount: 0,
  },

  reducers: {
    /**
     * Resets the bookings count to 0.
     */
    resetBookingsCount: (state) => {
      state.bookingsCount = 0
    },

    /**
     * Updates the bookings count.
     */
    setBookingsCount: (state, { payload }) => {
      state.bookingsCount = payload
    },

    /**
     * Resets the notes count to 0 based on the noteable type.
     *
     * @param {string} payload - The noteable type.
     */
    resetNotesCount: (state, { payload }) => {
      state.notesCount[payload] = 0
    },

    /**
     * Updates the notes count based on the noteable type.
     *
     * @param {string} payload.key - The noteable type.
     */
    setNotesCount: (state, { payload }) => {
      state.notesCount[payload.key] = payload.count
    },

    /**
     * Resets the Booking Update Notice count to 0.
     */
    resetBunsCount: (state) => {
      state.bunsCount = 0
    },

    /**
     * Updates the Booking Update Notice count.
     */
    setBunsCount: (state, { payload }) => {
      state.bunsCount = payload
    },
  },
})

export const {
  resetBookingsCount,
  resetNotesCount,
  setBookingsCount,
  setNotesCount,
  resetBunsCount,
  setBunsCount,
} = appSlice.actions

export default appSlice.reducer as any
