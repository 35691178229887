import reportService from 'services/report.service'
import ReportCompareByYear from 'modules/simple-crm/components/reports/ReportCompareByYear'

type Props = {
  className?: string;
};

export default function ReportCompareContactsByYear({ className }: Props) {
  return (
    <ReportCompareByYear
      className={className}
      title="Contacts: Comparison By Year"
      fetcher={reportService.compareContactsByYear}
    />
  )
}
