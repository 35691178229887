import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Form, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'

import useChart from 'hooks/useChart'
import useUtils from 'hooks/useUtils'

import useReport from 'hooks/useReport'

type Props = {
  className?: string;
  format?: 'number' | 'monetary';
  title: string;
  fetcher: (query: any) => Promise<any>;
};

export default function ReportCompareByYear({
  className,
  title,
  fetcher,
  format = 'number',
}: Props) {
  const { makeTwinBarChartOptions } = useChart()
  const { currentYear, yearOptions } = useReport()
  const { monthName, monify } = useUtils()

  const [year1, setYear1] = useState(currentYear - 1)
  const [year2, setYear2] = useState(currentYear)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<any>([])
  const [chartXAxis, setChartXAxis] = useState<string[]>([])
  const [year1Data, setYear1Data] = useState<number[]>([])
  const [year2Data, setYear2Data] = useState<number[]>([])

  useEffect(() => {
    getResults(year1, year2)
  }, [year1, year2])

  /**
   * Get results
   */
  const getResults = async (year1: number, year2: number) => {
    setResults([])
    setLoading(true)

    const { data }: any = await fetcher({ year1, year2 })

    const rows = []
    const xAxis = []
    const year1rows = []
    const year2rows = []

    for (let i = 1; i <= 12; i++) {
      xAxis.push(monthName(i))
      year1rows.push(data.year1[i])
      year2rows.push(data.year2[i])

      rows.push({
        month: i,
        year1: data.year1[i],
        year2: data.year2[i],
      })
    }

    setResults(rows)
    setChartXAxis(xAxis as string[])
    setYear1Data(year1rows as number[])
    setYear2Data(year2rows as number[])

    setLoading(false)
  }

  /**
   * Format data display based on `format` prop
   */
  const formatData = (data: number) => {
    switch (format) {
      case 'monetary':
        return monify(data)
      default:
        return data
    }
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">{title}</p>
          <Form.Group className="d-flex align-items-center gap-2">
            <FontAwesomeIcon icon="filter" className="me-2" />
            <Form.Select
              defaultValue={year1}
              onChange={(e: any) => setYear1(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
            <Form.Select
              defaultValue={year2}
              onChange={(e: any) => setYear2(e.target.value)}
              disabled={loading}
            >
              {yearOptions.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-md-5">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Month</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">{year1}</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">{year2}</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={2} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={2} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{monthName(result.month)}</td>
                    <td>{formatData(result.year1)}</td>
                    <td>{formatData(result.year2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-md-7">
            <ReactEChartsCore
              echarts={echarts}
              option={makeTwinBarChartOptions(
                chartXAxis,
                year1.toString(),
                year1Data,
                year2.toString(),
                year2Data
              )}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
