import SubtleBadge from 'components/common/SubtleBadge'
import useUtils from 'hooks/useUtils'
import { useEffect, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import bunServices from 'services/bun.service'
import { setBunsCount } from 'store/slices/appSlice'

const COL_COUNT = 1

type Props = {
  bookingId: number;
};

export default function BookingBuns({ bookingId }: Props) {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const { formatDate } = useUtils()
  const dispatch = useDispatch()

  useEffect(() => {
    getAllBUNs()
  }, [bookingId])

  // Fetch the list of BUNs
  const getAllBUNs = async () => {
    setLoading(true)
    const { data }: any = await bunServices.getAllByBookingId(bookingId)
    setList(data)
    dispatch(setBunsCount(data.length))
    setLoading(false)
  }

  return (
    <div>
      <Table responsive striped hover>
        <tbody className="fs-10">
          <tr>
            {loading && (
              <td colSpan={COL_COUNT} className="text-center">
                <Spinner />
              </td>
            )}
          </tr>
          <tr>
            {!loading && !list.length && (
              <td colSpan={COL_COUNT} className="text-center">
                Empty results
              </td>
            )}
          </tr>
          {list.map((item: any) => (
            <tr key={item.id} className="text-primary fw-semibold">
              <td>
                <div className="d-flex flex-column fs-10">
                  <div className="d-flex justify-content-between">
                    <p className="text-700 fw-semibold my-0">
                      Title: {item.title}
                    </p>
                    <div>
                      <span className="fs-11">
                        {formatDate(item.pivot?.sent_at)}
                      </span>
                      <SubtleBadge
                        className="text-capitalize ms-2"
                        bg={
                          item.pivot?.status === 'sent' ? 'success' : 'warning'
                        }
                      >
                        {item.pivot?.status}
                      </SubtleBadge>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>Reason: {item.reason}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span>Details: {item.details}</span>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
