import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import ToolBUNModal from 'modules/simple-crm/components/tools/ToolBUNModal'
import bunService from 'services/bun.service'

const COL_COUNT = 3

export default function ToolBookingNotification() {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any[]>([])
  const [showFormModal, setShowFormModal] = useState(false)

  // Fetch the data on mount
  useEffect(() => {
    getAllBUNs()
  }, [])

  /**
   * Handle event when the form is successfully submitted.
   */
  const handleSuccess = async () => {
    setShowFormModal(false)
    getAllBUNs()
  }

  // Fetch the list of BUNs
  const getAllBUNs = async () => {
    setLoading(true)

    const { data }: any = await bunService.getAll()

    setList(data)
    setLoading(false)
  }

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => setShowFormModal(true)}
        >
          <FontAwesomeIcon icon="plus" className="me-2" />
          Create
        </button>
      </div>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Title</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Reason</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Details</h6>
            </th>
          </tr>
        </thead>
        <tbody className="fs-10">
          <tr>
            {loading && (
              <td colSpan={COL_COUNT} className="text-center">
                <Spinner />
              </td>
            )}
          </tr>
          <tr>
            {!loading && !list.length && (
              <td colSpan={COL_COUNT} className="text-center">
                Empty results
              </td>
            )}
          </tr>
          {list.map((item) => (
            <tr key={item.id} className="text-primary fw-semibold">
              <td>{item.title}</td>
              <td>{item.reason}</td>
              <td>{item.details}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ToolBUNModal
        instance={null}
        show={showFormModal}
        onClose={() => setShowFormModal(false)}
        onSuccess={handleSuccess}
      />
    </div>
  )
}
