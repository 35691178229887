import { useEffect, useState } from 'react'
import { Card, Spinner, Table } from 'react-bootstrap'

import * as echarts from 'echarts/core'
import ReactEChartsCore from 'echarts-for-react/lib/core'

import cn from 'classnames'

import useChart from 'hooks/useChart'
import useUtils from 'hooks/useUtils'

type Props = {
  className?: string;
  title: string;
  fetcher: () => Promise<any>;
  valueType: 'number' | 'monetary';
};

export default function ReportTop10Records({
  className,
  title,
  valueType,
  fetcher,
}: Props) {
  const { makeBarChartOptions } = useChart()
  const { monify } = useUtils()

  const [chartXAxis, setChartXAxis] = useState<string[]>([])
  const [chartData, setChartData] = useState<any[]>([])

  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])

  // Get results on initial load
  useEffect(() => {
    getResults()
  }, [])

  /**
   * Get results
   */
  const getResults = async () => {
    setResults([])
    setLoading(true)

    const { data }: any = await fetcher()

    setResults(data)

    // Format x-axis for the chart
    setChartXAxis(data.map((result: any) => result.date))

    // Format data for the chart
    setChartData(data.map((result: any) => result.value))

    setLoading(false)
  }

  return (
    <Card className={cn(className)}>
      <Card.Body>
        <div className="d-flex flex-between-center mb-3">
          <p className="fw-bold m-0">{title}</p>
        </div>
        <hr />
        <div className="row g-5">
          <div className="col-lg-4">
            <Table responsive striped hover>
              <thead>
                <tr className="text-center">
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Date</h6>
                  </th>
                  <th scope="col">
                    <h6 className="text-700 fw-semibold">Value</h6>
                  </th>
                </tr>
              </thead>
              <tbody className="fs-10">
                <tr>
                  {loading && (
                    <td colSpan={2} className="text-center">
                      <Spinner />
                    </td>
                  )}
                </tr>
                <tr>
                  {!loading && !results.length && (
                    <td colSpan={2} className="text-center">
                      Empty results
                    </td>
                  )}
                </tr>
                {results.map((result: any, index: number) => (
                  <tr key={index} className="text-center">
                    <td>{result.date}</td>
                    {/* Render value based on valueType */}
                    <td>
                      {valueType === 'monetary'
                        ? monify(result.value)
                        : result.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="col-lg-8">
            <ReactEChartsCore
              echarts={echarts}
              option={makeBarChartOptions(chartXAxis, chartData)}
              style={{ height: '18.75rem' }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}
