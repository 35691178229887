import { Route, Routes } from 'react-router-dom'

import Error404 from 'components/errors/Error404'
import Login from 'components/authentication/split/Login'
import CRMSelectionPage from 'components/CRMSelectionPage'

import ErrorLayout from 'layouts/ErrorLayout'

import SimpleCRMRoutes from 'modules/simple-crm/routes/index'

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<CRMSelectionPage />} />

      {SimpleCRMRoutes}

      <Route path="/login" element={<Login />} />

      <Route element={<ErrorLayout />}>
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  )
}

export default Router
