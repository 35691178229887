import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface AppTooltipProps {
  id: string;
  children: React.ReactNode;
  title: string;
  tooltipClass?: string;
}

const AppTooltip = ({ id, children, title, tooltipClass }: AppTooltipProps) => (
  <OverlayTrigger
    overlay={
      <Tooltip className={tooltipClass} id={id}>
        {title}
      </Tooltip>
    }
  >
    <span>{children}</span>
  </OverlayTrigger>
)

export default AppTooltip
