// Prefix for Welcode discount codes
export const CODE_WELCOME_PREFIX = 'MSVG'

// MSVG5W - 5% of for MSC Voyager Club Member at level WELCOME
export const CODE_MSVG5W = `${CODE_WELCOME_PREFIX}5W`

// MSVG10W -  10% of for MSC Voyager Club Member at level WELCOME
export const CODE_MSVG10W = `${CODE_WELCOME_PREFIX}10W`

// MSVG15W -  15% of for MSC Voyager Club Member at level WELCOME
export const CODE_MSVG15W = `${CODE_WELCOME_PREFIX}15W`

// MSCCLU55 - If just a MSC Voyager Club Member and sailing is 9 months >
export const CODE_MSCCLU55 = 'MSCCLU55'

// MSCCLUB5 - If just a MSC Voyager Club Member and sailing is 9 months <
export const CODE_MSCCLUB5 = 'MSCCLUB5'

// YOUNG25 - If just two young adults both aged between 18 and 29
export const CODE_YOUNG25 = 'YOUNG25'
export const CODE_YOUNG17_DEP = 'YOUNG17'

// SENIOR25 - If just two seniors both aged over 65
export const CODE_SENIOR25 = 'SENIOR25'
export const CODE_SENIOR19_DEP = 'SENIOR19'

// SGPARENT - If just a single parent with single/multiple children
export const CODE_SGPARENT = 'SGPARENT'

export const discountLabel: Record<string, string> = {
  [CODE_YOUNG25]: 'Young Person Discount',
  [CODE_YOUNG17_DEP]: 'Young Person Discount',
  [CODE_SENIOR25]: 'Senior Person Discount',
  [CODE_SENIOR19_DEP]: 'Senior Person Discount',
  [CODE_MSCCLUB5]: 'Voyager Discount',
  [CODE_MSCCLU55]: 'Voyager Exclusives Discount',
  [CODE_SGPARENT]: 'Single Parent Discount',
  [CODE_MSVG5W]: 'Welcome 5%',
  [CODE_MSVG10W]: 'Welcome 10%',
  [CODE_MSVG15W]: 'Welcome 15%',
}
