import { callExternalApi } from 'services/external-api.service'

const endpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/buns`

/**
 * Get all BUNs.
 */
export const getAll = async () =>
  callExternalApi({
    config: {
      url: endpoint,
      method: 'GET',
    },
  })

/**
 * Get all BUNs by booking ID.
 */
export const getAllByBookingId = async (bookingId: number) =>
  callExternalApi({
    config: {
      url: `${endpoint}/bookings/${bookingId}`,
      method: 'GET',
    },
  })

/**
 * Send Booking Update Notification (BUN) to the external API.
 */
export const send = async (payload: any) =>
  callExternalApi({
    config: {
      url: endpoint,
      method: 'POST',
      data: payload,
    },
  })

const bunServices = {
  getAll,
  getAllByBookingId,
  send,
}

export default bunServices
